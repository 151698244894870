import { default as aboutDdLcFtJL1NMeta } from "/home/runner/work/web/web/pages/about.vue?macro=true";
import { default as addProvider7qzxalN55yMeta } from "/home/runner/work/web/web/pages/addProvider.vue?macro=true";
import { default as appointmentRequested6SgNGVtHIhMeta } from "/home/runner/work/web/web/pages/appointmentRequested.vue?macro=true";
import { default as appointments5j3ceDGuxTMeta } from "/home/runner/work/web/web/pages/appointments.vue?macro=true";
import { default as booking97QRSYclLRMeta } from "/home/runner/work/web/web/pages/booking.vue?macro=true";
import { default as contactzSTaobd69CMeta } from "/home/runner/work/web/web/pages/contact.vue?macro=true";
import { default as addresseLNAb6k6kBMeta } from "/home/runner/work/web/web/pages/create/address.vue?macro=true";
import { default as areaOEKrbZWZxaMeta } from "/home/runner/work/web/web/pages/create/area.vue?macro=true";
import { default as businessvCoq682tYyMeta } from "/home/runner/work/web/web/pages/create/business.vue?macro=true";
import { default as claim1ioY7qkcVaMeta } from "/home/runner/work/web/web/pages/create/claim.vue?macro=true";
import { default as contacthafJqwUntkMeta } from "/home/runner/work/web/web/pages/create/contact.vue?macro=true";
import { default as index5k5elmOxxSMeta } from "/home/runner/work/web/web/pages/create/index.vue?macro=true";
import { default as locationgBv8QVx61OMeta } from "/home/runner/work/web/web/pages/create/location.vue?macro=true";
import { default as index8Wx8VjfYI0Meta } from "/home/runner/work/web/web/pages/index.vue?macro=true";
import { default as landingoBrqhuQ4hqMeta } from "/home/runner/work/web/web/pages/landing.vue?macro=true";
import { default as logintl35qSNwFpMeta } from "/home/runner/work/web/web/pages/login.vue?macro=true";
import { default as calendaru9ET4GOXsvMeta } from "/home/runner/work/web/web/pages/manage/calendar.vue?macro=true";
import { default as clientswP5AVjTiFGMeta } from "/home/runner/work/web/web/pages/manage/clients.vue?macro=true";
import { default as inboxugYUTYRUegMeta } from "/home/runner/work/web/web/pages/manage/inbox.vue?macro=true";
import { default as messagesTXZoIpjTO4Meta } from "/home/runner/work/web/web/pages/manage/messages.vue?macro=true";
import { default as settingst7N6DfIQroMeta } from "/home/runner/work/web/web/pages/manage/settings.vue?macro=true";
import { default as zoomcallbackebhly7jQ9YMeta } from "/home/runner/work/web/web/pages/manage/zoomcallback.vue?macro=true";
import { default as managegQsVP3s34OMeta } from "/home/runner/work/web/web/pages/manage.vue?macro=true";
import { default as new0VrHbNZ9A2Meta } from "/home/runner/work/web/web/pages/new.vue?macro=true";
import { default as calendar2viFIzyXA2Meta } from "/home/runner/work/web/web/pages/onboarding/calendar.vue?macro=true";
import { default as callbacknlOdNu3GKDMeta } from "/home/runner/work/web/web/pages/onboarding/callback.vue?macro=true";
import { default as indexVM2AUiDHNdMeta } from "/home/runner/work/web/web/pages/onboarding/index.vue?macro=true";
import { default as locationjWuLSbMldcMeta } from "/home/runner/work/web/web/pages/onboarding/location.vue?macro=true";
import { default as scheduleURSW8kAypuMeta } from "/home/runner/work/web/web/pages/onboarding/schedule.vue?macro=true";
import { default as sessionleA8zahuYVMeta } from "/home/runner/work/web/web/pages/onboarding/session.vue?macro=true";
import { default as onboardingix0G1syiIUMeta } from "/home/runner/work/web/web/pages/onboarding.vue?macro=true";
import { default as profileemlkVyFT1mMeta } from "/home/runner/work/web/web/pages/profile.vue?macro=true";
import { default as providerjvAE7lb5YnMeta } from "/home/runner/work/web/web/pages/provider.vue?macro=true";
import { default as searchvd7r2lrCVBMeta } from "/home/runner/work/web/web/pages/search.vue?macro=true";
import { default as sitemapMtvTYpiarpMeta } from "/home/runner/work/web/web/pages/sitemap.vue?macro=true";
import { default as thankyouc3wepwCEfJMeta } from "/home/runner/work/web/web/pages/thankyou.vue?macro=true";
import { default as claimsSs9vFGLxA5Meta } from "/home/runner/work/web/web/pages/tools/claims.vue?macro=true";
import { default as providerssnNtFINy6VMeta } from "/home/runner/work/web/web/pages/tools/providers.vue?macro=true";
import { default as usersG0y3Own4RrMeta } from "/home/runner/work/web/web/pages/tools/users.vue?macro=true";
import { default as yourprivacy98sIRprMt3Meta } from "/home/runner/work/web/web/pages/yourprivacy.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/home/runner/work/web/web/pages/about.vue")
  },
  {
    name: "addProvider",
    path: "/addProvider",
    component: () => import("/home/runner/work/web/web/pages/addProvider.vue")
  },
  {
    name: "appointmentRequested",
    path: "/appointmentRequested",
    component: () => import("/home/runner/work/web/web/pages/appointmentRequested.vue")
  },
  {
    name: "appointments",
    path: "/appointments/:id?",
    meta: appointments5j3ceDGuxTMeta || {},
    component: () => import("/home/runner/work/web/web/pages/appointments.vue")
  },
  {
    name: "booking",
    path: "/booking/:bookingId",
    meta: booking97QRSYclLRMeta || {},
    component: () => import("/home/runner/work/web/web/pages/booking.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/home/runner/work/web/web/pages/contact.vue")
  },
  {
    name: "create-address",
    path: "/create/address",
    meta: addresseLNAb6k6kBMeta || {},
    component: () => import("/home/runner/work/web/web/pages/create/address.vue")
  },
  {
    name: "create-area",
    path: "/create/area",
    meta: areaOEKrbZWZxaMeta || {},
    component: () => import("/home/runner/work/web/web/pages/create/area.vue")
  },
  {
    name: "create-business",
    path: "/create/business",
    meta: businessvCoq682tYyMeta || {},
    component: () => import("/home/runner/work/web/web/pages/create/business.vue")
  },
  {
    name: "create-claim",
    path: "/create/claim",
    meta: claim1ioY7qkcVaMeta || {},
    component: () => import("/home/runner/work/web/web/pages/create/claim.vue")
  },
  {
    name: "create-contact",
    path: "/create/contact",
    meta: contacthafJqwUntkMeta || {},
    component: () => import("/home/runner/work/web/web/pages/create/contact.vue")
  },
  {
    name: "create",
    path: "/create",
    component: () => import("/home/runner/work/web/web/pages/create/index.vue")
  },
  {
    name: "create-location",
    path: "/create/location",
    meta: locationgBv8QVx61OMeta || {},
    component: () => import("/home/runner/work/web/web/pages/create/location.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/web/web/pages/index.vue")
  },
  {
    name: "landing",
    path: "/landing/:quickFilter?/:location?",
    meta: landingoBrqhuQ4hqMeta || {},
    alias: ["/:quickFilter(pt|ot|speech|aba|dmi|intensive)?/:location?"],
    component: () => import("/home/runner/work/web/web/pages/landing.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: logintl35qSNwFpMeta || {},
    component: () => import("/home/runner/work/web/web/pages/login.vue")
  },
  {
    name: "manage",
    path: "/manage",
    component: () => import("/home/runner/work/web/web/pages/manage.vue"),
    children: [
  {
    name: "manage-calendar",
    path: "calendar",
    component: () => import("/home/runner/work/web/web/pages/manage/calendar.vue")
  },
  {
    name: "manage-clients",
    path: "/manage/clients/:id?",
    meta: clientswP5AVjTiFGMeta || {},
    component: () => import("/home/runner/work/web/web/pages/manage/clients.vue")
  },
  {
    name: "manage-inbox",
    path: "/manage/inbox/:id?",
    meta: inboxugYUTYRUegMeta || {},
    alias: ["/manage/appointments/:id?"],
    component: () => import("/home/runner/work/web/web/pages/manage/inbox.vue")
  },
  {
    name: "manage-messages",
    path: "messages",
    component: () => import("/home/runner/work/web/web/pages/manage/messages.vue")
  },
  {
    name: "manage-settings",
    path: "settings",
    component: () => import("/home/runner/work/web/web/pages/manage/settings.vue")
  },
  {
    name: "manage-zoomcallback",
    path: "zoomcallback",
    component: () => import("/home/runner/work/web/web/pages/manage/zoomcallback.vue")
  }
]
  },
  {
    name: "new",
    path: "/new",
    component: () => import("/home/runner/work/web/web/pages/new.vue")
  },
  {
    name: onboardingix0G1syiIUMeta?.name,
    path: "/onboarding",
    meta: onboardingix0G1syiIUMeta || {},
    component: () => import("/home/runner/work/web/web/pages/onboarding.vue"),
    children: [
  {
    name: "onboarding-calendar",
    path: "calendar",
    component: () => import("/home/runner/work/web/web/pages/onboarding/calendar.vue")
  },
  {
    name: "onboarding-callback",
    path: "callback",
    component: () => import("/home/runner/work/web/web/pages/onboarding/callback.vue")
  },
  {
    name: "onboarding",
    path: "",
    component: () => import("/home/runner/work/web/web/pages/onboarding/index.vue")
  },
  {
    name: "onboarding-location",
    path: "location",
    component: () => import("/home/runner/work/web/web/pages/onboarding/location.vue")
  },
  {
    name: "onboarding-schedule",
    path: "schedule",
    component: () => import("/home/runner/work/web/web/pages/onboarding/schedule.vue")
  },
  {
    name: "onboarding-session",
    path: "session",
    component: () => import("/home/runner/work/web/web/pages/onboarding/session.vue")
  }
]
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/home/runner/work/web/web/pages/profile.vue")
  },
  {
    name: "provider",
    path: "/provider/:nameIgnore/:slug",
    meta: providerjvAE7lb5YnMeta || {},
    component: () => import("/home/runner/work/web/web/pages/provider.vue")
  },
  {
    name: "search",
    path: "/search",
    meta: searchvd7r2lrCVBMeta || {},
    component: () => import("/home/runner/work/web/web/pages/search.vue")
  },
  {
    name: "sitemap",
    path: "/sitemap",
    component: () => import("/home/runner/work/web/web/pages/sitemap.vue")
  },
  {
    name: "thankyou",
    path: "/thankyou",
    component: () => import("/home/runner/work/web/web/pages/thankyou.vue")
  },
  {
    name: "tools-claims",
    path: "/tools/claims",
    component: () => import("/home/runner/work/web/web/pages/tools/claims.vue")
  },
  {
    name: "tools-providers",
    path: "/tools/providers",
    component: () => import("/home/runner/work/web/web/pages/tools/providers.vue")
  },
  {
    name: "tools-users",
    path: "/tools/users",
    component: () => import("/home/runner/work/web/web/pages/tools/users.vue")
  },
  {
    name: "yourprivacy",
    path: "/yourprivacy",
    component: () => import("/home/runner/work/web/web/pages/yourprivacy.vue")
  }
]