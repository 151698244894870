export const useCookieConsent = (): boolean => {
  if (!window.getCkyConsent) return false;

  const cookieConsent = window.getCkyConsent();
  return !!cookieConsent?.categories?.analytics;
};

export const getSpoofedEmail = (email) => {
  if (!email) {
    return '';
  }
  const [name, domain] = email.split('@');
  return `${name.slice(0, 1)}***@${domain}`;
};
