import revive_payload_client_oVOxXaTdSR from "/home/runner/work/web/web/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.12_encoding@0.1.13_eslint@8.57.0_ioredis@_y7tvohuwk7i36lpqzsfg64pbne/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_QwyNmLyEPI from "/home/runner/work/web/web/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.12_encoding@0.1.13_eslint@8.57.0_ioredis@_y7tvohuwk7i36lpqzsfg64pbne/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_IWau66g6ns from "/home/runner/work/web/web/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.12_encoding@0.1.13_eslint@8.57.0_ioredis@_y7tvohuwk7i36lpqzsfg64pbne/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_oyTdu6VBqw from "/home/runner/work/web/web/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.24.0_vite@5.4.9_@types+node@20.16.12_terser@5_kt3e2ezvy6y6fjivhbhn54am3u/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_GS8H72mj5I from "/home/runner/work/web/web/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.12_encoding@0.1.13_eslint@8.57.0_ioredis@_y7tvohuwk7i36lpqzsfg64pbne/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_d2LD7zcRoJ from "/home/runner/work/web/web/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.12_encoding@0.1.13_eslint@8.57.0_ioredis@_y7tvohuwk7i36lpqzsfg64pbne/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_nn6AkXTYPe from "/home/runner/work/web/web/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.12_encoding@0.1.13_eslint@8.57.0_ioredis@_y7tvohuwk7i36lpqzsfg64pbne/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_0H1CUpdQT5 from "/home/runner/work/web/web/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.12_encoding@0.1.13_eslint@8.57.0_ioredis@_y7tvohuwk7i36lpqzsfg64pbne/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/web/web/.nuxt/components.plugin.mjs";
import prefetch_client_GNBcBavCKV from "/home/runner/work/web/web/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.12_encoding@0.1.13_eslint@8.57.0_ioredis@_y7tvohuwk7i36lpqzsfg64pbne/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import motion_oByCwtCPyC from "/home/runner/work/web/web/node_modules/.pnpm/@vueuse+motion@2.2.6_magicast@0.3.5_rollup@4.24.0_vue@3.5.12_typescript@5.5.3__webpack-sources@3.2.3/node_modules/@vueuse/motion/dist/nuxt/runtime/templates/motion.js";
import _01_datadog_client_kyKOfu6LUJ from "/home/runner/work/web/web/plugins/01.datadog.client.ts";
import _02_customFetch_client_Wfsz81JW0D from "/home/runner/work/web/web/plugins/02.customFetch.client.ts";
import firebase_client_zXNDa0wxFH from "/home/runner/work/web/web/plugins/firebase.client.ts";
import gesture_4SIjAfPLV5 from "/home/runner/work/web/web/plugins/gesture.ts";
export default [
  revive_payload_client_oVOxXaTdSR,
  unhead_QwyNmLyEPI,
  router_IWau66g6ns,
  _0_siteConfig_oyTdu6VBqw,
  payload_client_GS8H72mj5I,
  navigation_repaint_client_d2LD7zcRoJ,
  check_outdated_build_client_nn6AkXTYPe,
  chunk_reload_client_0H1CUpdQT5,
  components_plugin_KR1HBZs4kY,
  prefetch_client_GNBcBavCKV,
  motion_oByCwtCPyC,
  _01_datadog_client_kyKOfu6LUJ,
  _02_customFetch_client_Wfsz81JW0D,
  firebase_client_zXNDa0wxFH,
  gesture_4SIjAfPLV5
]