import type { User } from 'firebase/auth';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  onAuthStateChanged,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
  fetchSignInMethodsForEmail,
  sendPasswordResetEmail,
  sendEmailVerification,
  updateProfile,
} from 'firebase/auth';
import { datadogRum } from '@datadog/browser-rum';

export const createUser = async (email: string, password: string) => {
  const auth = getAuth();
  return await createUserWithEmailAndPassword(auth, email, password);
};

export const updateUserProfile = async (user: User, payload: any) => {
  return await updateProfile(user, payload);
};

export const verifyEmail = async (user: User) => {
  return await sendEmailVerification(user);
};

export const signInEmail = async (email: string, password: string) => {
  const auth = getAuth();
  return await signInWithEmailAndPassword(auth, email, password);
};

export const signInGoogle = async () => {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  return await signInWithPopup(auth, provider);
};

export const signInFacebook = async () => {
  const auth = getAuth();
  const provider = new FacebookAuthProvider();
  return await signInWithPopup(auth, provider);
};

export const signInApple = async () => {
  const auth = getAuth();
  const provider = new OAuthProvider('apple.com');
  return await signInWithPopup(auth, provider);
};

export const checkEmailAuth = async (email: string) => {
  const auth = getAuth();
  return await fetchSignInMethodsForEmail(auth, email);
};

export const resetPassword = async (email: string) => {
  const auth = getAuth();
  return await sendPasswordResetEmail(auth, email);
};

export const initUser = () => {
  const idTokenCookie = useCookie('idt', {
    secure: true,
  });
  const auth = getAuth();
  const firebaseUser = useFirebaseUser();
  firebaseUser.value = auth.currentUser;

  onAuthStateChanged(auth, async (user) => {
    console.log('auth state changed');
    firebaseUser.value = user;
    const idToken = await user?.getIdToken();
    idTokenCookie.value = idToken;
    const mpUser = useUser();

    if (!user) {
      // User is signed out
      mpUser.value = null;
      useFirebaseUserLoading().value = false;
      datadogRum.clearUser();
      return;
    }
    // Only refetch user if authId has changed
    if (!mpUser.value || mpUser.value.authId !== user.uid) {
      await useFetchUser();
    }
    useFirebaseUserLoading().value = false;

    if (user) {
      if (!user.email) {
        return;
      }
      datadogRum.setUser({
        id: user.uid,
        email: getSpoofedEmail(user.email),
      });
    }
  });
};

export const signOutUser = async () => {
  const auth = getAuth();
  await auth.signOut();
};
